// Variables

//@import "~startbootstrap-grayscale/scss/grayscale";
@import 'variables';
@import "~bootstrap/scss/bootstrap";
@import "tom-select/dist/scss/tom-select.bootstrap4";

@import 'mixins';
@import 'navbar';
@import 'masthead';
@import 'buttons';
@import 'about';
@import 'projects';
@import 'signup';
@import 'contact';


///////////

body {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.0625em;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

footer {
  padding: 5rem 0;
}


.logo-top > img{
  max-width: 235px;
}

@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,700&display=swap');

.bmd-label-static{
  color:black;
}

.ts-control{
  input{
    box-shadow: none!important;
    padding: 1.1rem 1.5rem;
  }
}


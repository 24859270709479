#mainNav {
  min-height: 56px;
  background-color: $black;
  .navbar-toggler {
    font-size: 80%;
    padding: 0.75rem;
    color: $white;
    background-color: $black;
    border: 0;
    box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1) !important;
    &:focus {
      outline: none;
      background-color: $primary;
    }
    &:hover {
      outline: none;
      background-color: $primary;
    }
    &:active {
      background-color: $primary !important;
    }
  }
  .navbar-brand {
    color: $white;
    font-weight: 600;
    padding: .9rem 0;
    text-transform: uppercase;
    .logo{
      max-width: 260px;
    }
  }
  .navbar-nav {
    .nav-item {
      &:hover {
        color: fade($white, 80%);
        outline: none;
        background-color: transparent;
      }
      &:active,
      &:focus {
        outline: none;
        background-color: transparent;
      }
      .nav-link{
        color: $white;
        &:focus {
          outline: none;
        }
        &:hover {
          color: black;
          outline: none;
          background-color: $primary;
        }
        &:active {

        }
      }
    }
  }
  @media (min-width: 992px) {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    transition: background-color 0.3s ease-in-out;
    .navbar-brand {
      padding: .5rem 0;
      color: fade-out($white, .5);
      .logo{
        max-width: 260px;
      }
    }
    .nav-link {
      text-transform: uppercase;
      transition: none;
      padding: 2rem 1.5rem;
      color: $white;
      &:hover {
        color: $white;
        background-color: $primary;
      }
      &:active {
        color: $white;
      }
    }
    &.navbar-shrink {
      .navbar-brand {
        color: $white;
        .logo{
          max-width: 180px;
        }
      }
      .nav-link {
        color: $white;
        padding: 1.5rem 1.5rem 1.25rem;
        border-bottom: 0.25rem solid transparent;
        &:hover {
          color: $white;
        }
        &.active {
          color: $white;
          outline: none;
          border-bottom: 0.25rem solid $primary;
        }
      }
    }
  }
}

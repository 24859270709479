.btn {
  box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1) !important;
  font-weight: 600;
  border: none;
  border-radius: 0.4rem;
  padding: 12px;
}

.btn-primary {
  color: $white;
  background-color: $gray-900;
  &:hover {
    background-color: $primary;
  }
  &:focus {
    background-color: $primary;
    color: $white;
  }
  &:active {
    background-color: $primary !important;
  }
}

.about-section {
  padding-top: 10rem;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
  background-position: center -60px;
  p {
    margin-bottom: 0.2rem;
    line-height: 2.8rem;
  }
}

h1{
  color:$black;
  font-size: 2.5rem;
  text-transform: uppercase;
}

h2{
  color: $primary;
  font-size: 2rem;
  margin-top: 1rem;
}

h3{
  color: $grey;
  font-size: 1.5rem;
}

.about-description{
  color: $primary;
  font-size: 1.5rem;
}
.about-speaker{
  color: $grey;
  font-size: 1.5rem;
}
.about-date{
  font-size: 1.5rem;
}

.figure-img{
  /*border-top: 2px solid $yellow;
  border-left: 2px solid $yellow;*/
  max-width: 225px;
}
.merz-line{
  border-bottom: 6px solid $yellow;
}
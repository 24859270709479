.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 15rem;
  padding: 15rem 0;
  /*background: url('images/test-1.jpg');*/
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: 0.8rem;
    color: $yellow;
  }
  h2 {
    max-width: 20rem;
    font-size: 1rem;
  }
  @media(min-width: 768px) {
    h1 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  @media(min-width: 992px) {
    height: 100vh;
    padding: 0;
    h1 {
      font-size: 6.5rem;
      line-height: 6.5rem;
      letter-spacing: 0.8rem;
    }
    h2 {
      max-width: 30rem;
      font-size: 1.25rem;
    }
  }
}

.muse {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 15rem;
  padding: 15rem 0;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;

  @media(min-width: 992px) {
    height: 100vh;
    padding: 0;
  }
}
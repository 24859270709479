// Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #3e3e3e !default;
$black: #000000 !default;

$blue:    #6da2b7 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #e4df00 !default;
$green:   #28a745 !default;
$teal:    #00817d !default;
$cyan:    #17a2b8 !default;
$grey: #575756 !default;

$primary:       $teal !default;
.signup-section {
  padding: 5rem 0;
  input, select {
    /*box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0,0,0,.1) !important;*/
    padding: 1rem 1.1rem;
    height: auto;
    font-size: 80%;
    /*text-transform: uppercase;*/
    letter-spacing: 0.1rem;
  }
  .required-field{
    &::placeholder{
      color:$gray-600 !important;
    }
    &::-ms-input-placeholder{
      color:$gray-600 !important;
    }
    &::after{
      content:"*";
    }
  }
  .alert{
    box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0,0,0,.1) !important;
    padding: 1.25rem 2rem;
    height: auto;
    font-size: 80%;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    border: 0;
  }
}
